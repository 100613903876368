import React, { useEffect, useState } from "react";
import styles from "./diploma.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { getCertificate } from "../services/Certificate.Service";
import { Certificate } from "../models/Certificate.Interface";

export const DiplomaPage: React.FC = () => {
  return (
    <div className={styles.App}>
      <div className={styles.header}>
        <a
          href="https://www.getcircles.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/circles_logo.svg"
            alt="Circles Logo"
            className={styles.logo}
          />
        </a>
      </div>
      <DiplomaPageBody />
    </div>
  );
};

const DiplomaPageBody = () => {
  const params = useParams();
  const certificateId = params.certificateId;

  const navigate = useNavigate();

  const [certificate, setCertificate] = useState<Certificate | undefined>();

  const goToCircles = () => {
    window.open("https://www.getcircles.org", "_blank", "noopener,noreferrer");
  };

  const fetchCertificate = async (certificateId: string) => {
    try {
      const result = await getCertificate(certificateId);
      setCertificate(result);
    } catch (error: any) {
      navigate(`/not-found`);
    }
  };

  useEffect(() => {
    if (certificateId && certificateId.length === 8) {
      fetchCertificate(certificateId);
    } else {
      navigate(`/not-found`);
    }
  }, [certificateId]);

  if (!certificate) {
    return (
      <div className={styles.spinnerSection}>
        <div className={styles.customSpinner}></div>
      </div>
    );
  }

  return (
    <div className={styles.content}>
      <div className={styles.leftColumn}>
        <img
          className={styles.certificateImg}
          src={`${certificate.certificateURL}?t=${new Date().getTime()}`}
          alt="Diploma"
        />
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.circlesInfo}>
          <div className={styles.circlesTagline}>
            <span>
              Develop 21<sup>st</sup> Century Skills with Circles
            </span>
          </div>
          <div className={styles.circlesDescription}>
            Circles is a provider of distance learning with a twist. Here,
            students have an active learning experience learning alongside
            friends they meet within the platform.
          </div>
          <button onClick={goToCircles} className={styles.button}>
            Visit Circles
          </button>
        </div>
      </div>
    </div>
  );
};
